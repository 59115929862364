import { useRouter } from 'next/router'
import { FC, useContext } from 'react'

import { AuthContext } from '../../providers/MPAuthContextProvider'
import { AppContext } from '../../providers'

import { registerGroupMeAccount } from './ChannelWall/GroupMeLogin/GroupMeAuthHelpers'
import { useCurrentUser } from 'hooks'

const GROUPME_LOGIN_PATH = '/login-groupme'

const LoginPage: FC = () => {
  const { auth } = useContext(AuthContext)
  const { setToast } = useContext(AppContext)
  const router = useRouter()
  const currentUser = useCurrentUser()

  const redirectRoute =
    localStorage.getItem('routeBeforeRedirect') || '/manage/settings'

  if (
    auth &&
    router.pathname === GROUPME_LOGIN_PATH &&
    router.query?.access_token
  ) {
    registerGroupMeAccount({
      accessToken: router.query?.access_token as string,
      onSuccess: () => {
        currentUser?.refetchCurrentUser()
        setToast({ status: 'success', description: 'GroupMe Registered' })
        router
          .replace(redirectRoute)
          .then(() => localStorage.removeItem('routeBeforeRedirect'))
          .catch(err => {
            throw err
          })
      },
      onError: () =>
        setToast({
          status: 'error',
          description: 'Error Registering GroupMe',
        }),
    })
  } else if (auth) {
    // preserve drawer state from query param if present
    const { drawer } = router.query

    router
      .replace({
        pathname: '/newsfeed/global',
        ...(drawer && { query: { drawer } }),
      })
      .catch(err => {
        throw err
      })
  }
  return <></>
}

export default LoginPage
